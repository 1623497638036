import React from 'react';
import { FaCheckCircle, FaExclamationTriangle, FaTimesCircle } from 'react-icons/fa';

const TermsOfUse = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-6">Terms of Use</h1>
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow">
        <div className="mb-8">
          <div className="flex items-center mb-4">
            <FaCheckCircle className="text-green-500 text-4xl mr-4" />
            <h2 className="text-2xl font-semibold">FreightValidated</h2>
          </div>
          <p className="text-gray-700">
            A FreightValidated entity has passed legal compliance assessment, has active MC & DOT Authority, meets the minimum requirements of 49 CFR including but not limited to part 390.5T (Principal Place of Business), Complies with Public Law 112-141 (including para 32103(D)(i) regarding disclosure of affiliation with other Operating Authority Certificate Holders and Applicants) and other state and federal regulations. The platform will also advise if MC Number is operating as a Broker, Carrier or Both. If the Carrier/Broker is “FreightValidated”, the carrier then meets the basic criteria for further carrier safety vetting as appropriate through company compliance protocols.
          </p>
        </div>

        <div className="mb-8">
          <div className="flex items-center mb-4">
            <FaExclamationTriangle className="text-yellow-500 text-4xl mr-4" />
            <h2 className="text-2xl font-semibold">Unable to Completely Validate</h2>
          </div>
          <p className="text-gray-700">
            Carrier/Broker has active MC & DOT Authority, meets the minimum requirements of 49 CFR, MAP-21, and other local, state and federal regulations. Has active Operating Authority and meets minimum verification requirements with local Secretary of State.
          </p>
          <p className="text-gray-700 font-bold mt-2">HOWEVER – HAS BEEN FLAGGED DURING RISK ASSESSMENT VERIFICATION PROCESS DUE TO:</p>
          <p className="text-gray-700">
            Recent changes in ownership or contact information with the secretary or state of fmcsa, number of dispatchers not in proportion with number of trucks, a broker portraying itself as a motor carrier in advertising, misleading information regarding where which country the entity is conducting business.
          </p>
          <p className="text-gray-700 mt-2">
            FreightValidate Recommends that Subscribers initiate deeper review through its company’s vetting protocols. It is important to emphasize that the entity is NOT disqualified my the platform. We simply recommend additional vetting to make an informed business decision.
          </p>
        </div>

        <div className="mb-8">
          <div className="flex items-center mb-4">
            <FaExclamationTriangle className="text-yellow-500 text-4xl mr-4" />
            <h2 className="text-2xl font-semibold">Additional Review of COI is Recommended</h2>
          </div>
          <p className="text-gray-700">
            A Recommendation to further review of the Certificate of Insurance should not be interpreted as any indication as to whether any carrier should or should not be used; nor does it imply that the Motor Carrier does not have proper insurance coverage. It simply means that the FreightValidate platform is unable to sufficiently verify the information contained on the Motor Carrier's COI. For example, the certificate holder's name on the COI may not match the name and address of the motor carrier moving the shipment, the Contact Information for the Insurance Company or Broker may be blank or illegible, or the policy number is not listed, etc. We recommend that brokers and shippers verify that certificate contains correct Motor Carrier’s Policy Number & Effective Dates, the Insurance Company's Name, Contact at the Company, Phone Number and Email Information, The VIN Numbers of the Insured Vehicles, and the Types of Coverages and Limits Provided. We further recommend that if there are any questions regarding additional insured, waivers of subrogation and cancellation provisions, that this information is properly verified via email with the insurance provider. When in doubt of any issues pertaining to insurance coverages, FreightValidate recommends that brokers consider detailed vetting of the Motor Carrier Certificates of Insurance by independent subject matter experts such as Vicarious Liability Risk Management LLC.
          </p>
        </div>

        <div className="mb-8">
          <div className="flex items-center mb-4">
            <FaTimesCircle className="text-red-500 text-4xl mr-4" />
            <h2 className="text-2xl font-semibold">Additional Vetting Required</h2>
          </div>
          <p className="text-gray-700">
            If you encounter a RED X next to a Broker, Carrier, or Forwarder on our platform, it indicates that the entity is NOT COMPLIANT with either state or federal regulations.
          </p>
          <p className="text-gray-700 font-bold mt-2">Implications:</p>
          <p className="text-gray-700">
            There is no need for further scrutiny in this case—such entities should be disqualified continuing in the freight process. The RED X serves as a clear and immediate signal that the entity fails to meet essential regulatory standards.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
