import React from 'react';

const TermsConditions = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-6">Terms & Conditions</h1>
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow">
        <p className="text-gray-700 mb-4">
          Welcome to our website that provides verification of identity and FMCSA compliance information related to Principal place of business, Motor carrier authority, DOT authority, MAP-21 compliance for freight brokers, motor carriers, and shippers. Please read the following terms and conditions carefully before using our services.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Acceptance of Terms</h2>
        <p className="text-gray-700 mb-4">
          By using our website, you agree to be bound by these terms and conditions. If you do not agree with any of these terms, please do not use our website.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Verification and Compliance</h2>
        <p className="text-gray-700 mb-4">
          Our website provides verification and compliance information related to Principal place of business, Motor carrier authority, DOT authority, MAP-21 compliance, and other data points as they refer to identity verification for freight brokers, motor carriers, and shippers. However, we do not endorse or guarantee the accuracy of the information provided by our website. It is your responsibility to verify the information provided by our website.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Use of Information</h2>
        <p className="text-gray-700 mb-4">
          The information provided by our website is for informational purposes only. You may use the information provided by our website for your personal or business use, but you may not use it for any commercial or illegal purpose.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Liability</h2>
        <p className="text-gray-700 mb-4">
          We are not liable for any damages resulting from the use of our website or the information provided by our website. We do not guarantee the accuracy, completeness, or timeliness of the information provided by our website.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Intellectual Property</h2>
        <p className="text-gray-700 mb-4">
          Our website and its content are protected by copyright and other intellectual property laws. You may not reproduce, modify, distribute, or use any part of our website without our prior written consent.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Third-Party Websites</h2>
        <p className="text-gray-700 mb-4">
          Our website may contain links to third-party websites. We are not responsible for the content or privacy practices of these websites.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Privacy</h2>
        <p className="text-gray-700 mb-4">
          We respect your privacy and we are committed to protecting your personal information. Please refer to our Privacy Policy for more information.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Changes to Terms and Conditions</h2>
        <p className="text-gray-700 mb-4">
          We reserve the right to change these terms and conditions at any time. Your continued use of our website after any changes to these terms and conditions will constitute your acceptance of the changes.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Governing Law</h2>
        <p className="text-gray-700 mb-4">
          These terms and conditions shall be governed by and construed in accordance with the laws of the United States.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Subscriptions</h2>
        <p className="text-gray-700 mb-4">
          FreightValidate is for use by Active Subscribers only. Sharing Login Information or results from the Validator is prohibited. Any violation of this policy will result in immediate termination of your subscription.
        </p>
        <h2 className="text-2xl font-semibold mb-4">Contact Information</h2>
        <p className="text-gray-700">
          If you have any questions or concerns about these terms and conditions, please contact us at <a href="mailto:info@FreightValidate.com" className="text-blue-500">info@FreightValidate.com</a>.
        </p>
      </div>
    </div>
  );
};

export default TermsConditions;
