import React, { useEffect, useRef } from 'react';

const SearchResultsPopup = ({ searchResults, handleCompanySelect, closePopup }) => {
  const popupRef = useRef();

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        closePopup();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popupRef, closePopup]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div ref={popupRef} className="bg-white rounded-lg p-6 w-full max-w-md">
        <h2 className="text-xl font-bold mb-4 text-[#307a3f]">Select a Company</h2>
        {searchResults.length > 0 ? (
          <ul className="space-y-2">
            {searchResults.map((company) => (
              <li
                key={company.ReportID}
                className="cursor-pointer p-2 border border-gray-300 rounded hover:bg-gray-100"
                onClick={() => handleCompanySelect(company)}
              >
                <div className="font-semibold">{company.CompanyName}</div>
                <div className="text-sm text-gray-600">{company.Address}, {company.City}, {company.State}, {company.ZipCode}</div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600">No results found.</p>
        )}
      </div>
    </div>
  );
};

export default SearchResultsPopup;
