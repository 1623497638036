import React from 'react';

const ContactUs = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-6">Contact Us</h1>
      <div className="max-w-2xl mx-auto bg-white p-6 rounded-lg shadow">
        <h2 className="text-2xl font-semibold mb-4">For Sales Inquiries:</h2>
        <p>Email: <a href="mailto:sales@freightvalidate.com" className="text-blue-600">sales@freightvalidate.com</a></p>

        <h2 className="text-2xl font-semibold mt-6 mb-4">For Support and Tech Inquiries:</h2>
        <p>Email: <a href="mailto:support@freightvalidate.com" className="text-blue-600">support@freightvalidate.com</a></p>
      </div>
    </div>
  );
};

export default ContactUs;
