import React from 'react';

const PaymentPopup = ({ closePaymentPopup, purchaseLookups, loading }) => {
  const isAnyLoading = Object.values(loading).some(value => value);

  return (
    <div className="fixed inset-0 flex justify-center bg-black bg-opacity-75 z-50 animate-fadeIn overflow-y-auto">
      <div className="bg-white text-black p-8 rounded-lg shadow-lg w-full max-w-3xl relative border border-gray-300 overflow-y-auto m-4">
        <button
          className="absolute top-4 right-4 text-black text-2xl"
          onClick={closePaymentPopup}
        >
          &times;
        </button>

        {/* <div className="mt-8 text-center bg-[#307a3f] text-white p-4 rounded-lg mb-6">
          <h3 className="text-xl font-semibold mb-4">Are you looking for an Individual or Company subscription plan with UNLIMITED Lookups?</h3>
          <p className="mb-6">FreightValidate.Com may be what you are looking for. <a href="https://www.freightvalidate.com" className="text-white font-bold underline">Click Here</a> to be connected</p>
          <img src="/fv-logo.png" alt="FreightValidate Logo" className="mx-auto w-32" />
        </div> */}

        <h2 className="text-3xl font-bold mb-6 text-center">On-Demand Pricing</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="p-6 border border-gray-300 rounded-lg shadow hover:shadow-lg transition duration-200 flex flex-col items-center">
            <h3 className="text-2xl font-semibold mb-2 text-[#307a3f]">1 Lookup</h3>
            <p className="text-4xl font-bold mb-4">$4.99</p>
            <p className="text-gray-600 mb-6 text-center">Single MC Numbers or Shippers lookup</p>
            <button
              className="w-full bg-[#307a3f] text-white py-3 rounded-lg hover:bg-[#236e34] transition duration-200"
              onClick={() => purchaseLookups('checkout-one-time-payment', 'oneLookup')}
              disabled={isAnyLoading}
            >
              {loading.oneLookup ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                'Purchase'
              )}
            </button>
          </div>
          <div className="p-6 border border-gray-300 rounded-lg shadow hover:shadow-lg transition duration-200 flex flex-col items-center">
            <h3 className="text-2xl font-semibold mb-2 text-[#307a3f]">10 Lookups</h3>
            <p className="text-4xl font-bold mb-4">$15.99</p>
            <p className="text-gray-600 mb-6 text-center">10 MC Numbers or Shippers lookups</p>
            <button
              className="w-full bg-[#307a3f] text-white py-3 rounded-lg hover:bg-[#236e34] transition duration-200"
              onClick={() => purchaseLookups('checkout-10-times-payment', '10Lookups')}
              disabled={isAnyLoading}
            >
              {loading['10Lookups'] ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                'Purchase'
              )}
            </button>
          </div>
          <div className="p-6 border border-gray-300 rounded-lg shadow hover:shadow-lg transition duration-200 flex flex-col items-center">
            <h3 className="text-2xl font-semibold mb-2 text-[#307a3f]">25 Lookups</h3>
            <p className="text-4xl font-bold mb-4">$29.99</p>
            <p className="text-gray-600 mb-6 text-center">25 MC Numbers or Shippers lookups</p>
            <button
              className="w-full bg-[#307a3f] text-white py-3 rounded-lg hover:bg-[#236e34] transition duration-200"
              onClick={() => purchaseLookups('checkout-25-times-payment', '25Lookups')}
              disabled={isAnyLoading}
            >
              {loading['25Lookups'] ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                'Purchase'
              )}
            </button>
          </div>
          <div className="p-6 border border-gray-300 rounded-lg shadow hover:shadow-lg transition duration-200 flex flex-col items-center">
            <h3 className="text-2xl font-semibold mb-2 text-[#307a3f]">50 Lookups</h3>
            <p className="text-4xl font-bold mb-4">$59.99</p>
            <p className="text-gray-600 mb-6 text-center">50 MC Numbers or Shippers lookups</p>
            <button
              className="w-full bg-[#307a3f] text-white py-3 rounded-lg hover:bg-[#236e34] transition duration-200"
              onClick={() => purchaseLookups('checkout-50-times-payment', '50Lookups')}
              disabled={isAnyLoading}
            >
              {loading['50Lookups'] ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                'Purchase'
              )}
            </button>
          </div>
          <div className="p-6 border border-gray-300 rounded-lg shadow hover:shadow-lg transition duration-200 flex flex-col items-center">
            <h3 className="text-2xl font-semibold mb-2 text-[#307a3f]">100 Lookups</h3>
            <p className="text-4xl font-bold mb-4">$109.99</p>
            <p className="text-gray-600 mb-6 text-center">100 MC Numbers or Shippers lookups</p>
            <button
              className="w-full bg-[#307a3f] text-white py-3 rounded-lg hover:bg-[#236e34] transition duration-200"
              onClick={() => purchaseLookups('checkout-100-times-payment', '100Lookups')}
              disabled={isAnyLoading}
            >
              {loading['100Lookups'] ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                'Purchase'
              )}
            </button>
          </div>
          <div className="p-6 border border-gray-300 rounded-lg shadow hover:shadow-lg transition duration-200 flex flex-col items-center">
            <h3 className="text-2xl font-semibold mb-2 text-[#307a3f]">1000 Lookups</h3>
            <p className="text-4xl font-bold mb-4">$599.00</p>
            <p className="text-gray-600 mb-6 text-center">1000 MC Numbers or Shippers lookups</p>
            <button
              className="w-full bg-[#307a3f] text-white py-3 rounded-lg hover:bg-[#236e34] transition duration-200"
              onClick={() => purchaseLookups('checkout-1000-times-payment', '1000Lookups')}
              disabled={isAnyLoading}
            >
              {loading['1000Lookups'] ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                'Purchase'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPopup;
