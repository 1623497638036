import axios from 'axios';
import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import PaymentPopup from './PaymentPopup';
import RemainingCodesPopup from './RemainingCodesPopup';
import InfoPopup from './InfoPopup';
import SearchResultsPopup from './SearchResultsPopup';
import ReportPopup from './ReportPopup';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ValidationPage = () => {
  const [mcNumber, setMcNumber] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [purchaseCode, setPurchaseCode] = useState('');
  const [isPaymentPopupOpen, setIsPaymentPopupOpen] = useState(false);
  const [loading, setLoading] = useState({ oneLookup: false, tenLookups: false, validation: false, search: false });
  const [reportData, setReportData] = useState(null);
  const [docType, setDocType] = useState('MC number');
  const [isRemainingCodesPopupOpen, setIsRemainingCodesPopupOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isSearchResultsPopupOpen, setIsSearchResultsPopupOpen] = useState(false);
  const [isReportPopupOpen, setIsReportPopupOpen] = useState(false);
  const [isMiddleSpinnerVisible, setIsMiddleSpinnerVisible] = useState(false);


  const closeSearchResultsPopup = () => {
    setIsSearchResultsPopupOpen(false);
  };
  

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  useEffect(() => {
    const { code } = queryString.parse(window.location.search);
    if (code) {
      setPurchaseCode(code);
    }
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    setLoading((prev) => ({ ...prev, search: true }));

    const query = docType === 'MC number' ? mcNumber : companyName;
    const type = docType === 'MC number' ? 'mc' : 'company';

    axios.post(`${BASE_URL}/api/lookup/search`, {
      query: query,
      code: purchaseCode,
      type: type
    })
      .then((response) => {
        setLoading((prev) => ({ ...prev, search: false }));
        setSearchResults(response.data.data);
        setIsSearchResultsPopupOpen(true);
      })
      .catch((error) => {
        setLoading((prev) => ({ ...prev, search: false }));
        console.error('Search error:', error);
        alert(error.response.data.message);
      });
  };

  const handleCompanySelect = (company) => {
    setSelectedCompany(company);
    setIsSearchResultsPopupOpen(false);
     // Show middle loading spinner
     setIsMiddleSpinnerVisible(true);

    // Assume the report data is available after selecting the company
    setLoading((prev) => ({ ...prev, validation: true }));
    axios.post(`${BASE_URL}/api/lookup/get-report`, { "report_id": company.ReportID, "code": purchaseCode })
      .then((response) => {
        setLoading((prev) => ({ ...prev, validation: false }));
        setReportData(response.data.data);
        setIsReportPopupOpen(true);
      })
      .catch((error) => {
        setLoading((prev) => ({ ...prev, validation: false }));
        console.error('Validation error:', error);
        setReportData(null);
        setIsReportPopupOpen(false);
        alert(error.response.data.message);
      })
      .finally(() => {
        // Hide middle loading spinner
        setIsMiddleSpinnerVisible(false);
      });
  };

  const openRemainingCodesPopup = () => {
    setIsRemainingCodesPopupOpen(true);
  };

  const closeRemainingCodesPopup = () => {
    setIsRemainingCodesPopupOpen(false);
  };

  const openPaymentPopup = () => {
    setIsPaymentPopupOpen(true);
  };

  const closePaymentPopup = () => {
    setIsPaymentPopupOpen(false);
  };

  const closeReportPopup = () => {
    setIsReportPopupOpen(false);
  };

  const purchaseLookups = (endpoint, plan) => {
    setLoading((prev) => ({ ...prev, [plan]: true }));
    axios.get(`${BASE_URL}/api/payment/${endpoint}`)
      .then((response) => {
        setLoading((prev) => ({ ...prev, [plan]: false }));
        if (response.data) {
          console.log('Payment success:', response.data);
          if (response.data.checkoutUrl) {
            window.location.href = response.data.checkoutUrl;
          }
        }
      })
      .catch((error) => {
        setLoading((prev) => ({ ...prev, [plan]: false }));
        console.error('Payment error:', error);
      });
  };

  return (
    <div className="flex flex-col items-center justify-start min-h-screen bg-white relative">
      {isMiddleSpinnerVisible && (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
    <svg className="animate-spin h-12 w-12 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
    </svg>
  </div>
)}

      <header className="w-full bg-[#307a3f] text-white py-4">
        <nav className="container mx-auto flex justify-end items-center px-4 flex-wrap gap-4">
          <div className="flex space-x-4">
            <p onClick={openPaymentPopup} className="text-white cursor-pointer">Pricing</p>
            <a href="/contact" className="text-white">Contact Us</a>
            <a href="/about" className="text-white">About Us</a>
          </div>
          <button onClick={openRemainingCodesPopup} className="bg-white text-[#307a3f] px-4 py-2 rounded-lg mt-2 sm:mt-0">
            Search for Remaining Codes
          </button>
          <button onClick={openPaymentPopup} className="bg-white text-[#307a3f] px-4 py-2 rounded-lg mt-2 sm:mt-0">
            Buy Credits
          </button>
        </nav>
      </header>
      <InfoPopup isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)} />

      {isRemainingCodesPopupOpen && (
        <RemainingCodesPopup onClose={closeRemainingCodesPopup} />
      )}

      <div className="w-full flex flex-col items-center mt-8 px-4">
        <img className="w-70 mb-4" src="/white-logo.png" alt="logo" />
        <h1 className="text-3xl font-bold text-[#307a3f]">Enhance Your Freight Security with Credit Scores and Days-to-Pay Indicators</h1>
        <div className="flex flex-col items-center bg-white p-8 rounded-lg shadow-lg w-full max-w-md border border-[#307a3f] md:w-full w-[90%] mt-8">
          <form onSubmit={handleSearch}>
            <div className="mb-4">
              <label htmlFor="docType" className="block text-sm font-bold text-black-700">
                Enter MC Number or Company Name
              </label>
              <div className="flex items-center mt-2">
                <select
                  id="docType"
                  value={docType}
                  onChange={(e) => {
                    setDocType(e.target.value);
                    setMcNumber('');
                    setCompanyName('');
                    setSearchResults([]);
                    setSelectedCompany(null);
                  }}
                  className="mr-2 p-2 border rounded shadow-sm focus:ring-[#307a3f] focus:border-[#307a3f]"
                >
                  <option value="MC number">MC Number</option>
                  <option value="company name">Company Name</option>
                </select>
                {docType === 'MC number' ? (
                  <input
                    type="text"
                    id="mcNumber"
                    value={mcNumber}
                    onChange={(e) => setMcNumber(e.target.value)}
                    placeholder={`Enter ${docType}`}
                    className="p-2 w-full border rounded shadow-sm focus:ring-[#307a3f] focus:border-[#307a3f]"
                    required
                  />
                ) : (
                  <input
                    type="text"
                    id="companyName"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    placeholder={`Enter ${docType}`}
                    className="p-2 w-full border rounded shadow-sm focus:ring-[#307a3f] focus:border-[#307a3f]"
                    required
                  />
                )}
              </div>
            </div>
            <div className="mb-6">
              <label htmlFor="purchaseCode" className="block text-sm font-bold text-black-700">
                Enter Code
              </label>
              <input
                type="text"
                id="purchaseCode"
                value={purchaseCode}
                onChange={(e) => setPurchaseCode(e.target.value)}
                className="mt-2 p-2 w-full border rounded shadow-sm focus:ring-[#307a3f] focus:border-[#307a3f]"
                required
              />
            </div>
            {!purchaseCode && (
              <div className="text-sm mb-6">
                Don't have a purchase code yet? <button type="button" className="text-[#307a3f]" onClick={openPaymentPopup}>Purchase now</button>
              </div>
            )}
            <button
              type="submit"
              className="w-full bg-[#307a3f] text-white py-2 rounded-lg transition duration-200"
              disabled={loading.search}
            >
              {loading.search ? (
                <svg className="animate-spin h-5 w-5 text-white mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8H4z"></path>
                </svg>
              ) : (
                'Search'
              )}
            </button>
          </form>
        </div>
        <p className="mt-4 max-w-4xl mx-auto text-gray-700 text-center px-4">
          In the freight industry, credit scores and days-to-pay indicators are crucial tools for assessing the reliability of business partners and avoiding fraudulent activity.
          <br /><br />
          For instance, if a freight broker suddenly shows a significant increase in the number of days it takes to pay carriers, this could signal financial instability, identity theft, Non-Disclosed Purchase of an MC number by a corrupt person or company, or potential for planned non-payment before going out of business.
          <br /><br />
          Using FreightCredit alongside FreightValidate (http://www.FreightValidate.com) equips carriers and freight brokers with invaluable information for making informed business decisions.
          <br /><br />
          Here's why this is important:
        </p>
        <ul className="mt-4 max-w-4xl mx-auto text-gray-700 list-disc list-inside">
          <li><strong>Early Fraud Detection:</strong> Spotting unusual payment delays can alert you to potential fraud, allowing you to take preventive measures before it's too late.</li>
          <li><strong>Financial Stability Assessment:</strong> Credit scores provide a snapshot of a company’s financial health, helping you assess the risk of doing business with them.</li>
          <li><strong>Payment Assurance:</strong> Knowing the payment habits of shippers and brokers ensures you can make timely decisions with the confidence that you'll be paid for your services.</li>
          <li><strong>Risk Management:</strong> By understanding the financial behavior of your partners, you can mitigate risks and protect your business from potential losses.</li>
          <li><strong>Competitive Advantage:</strong> Access to real-time credit scores and payment data gives you a competitive edge, allowing you to make smarter, faster decisions.</li>
        </ul>
        <p className="mt-4 max-w-4xl mx-auto text-gray-700 text-center px-4">
          Recognizing that the consumer finance world relies heavily on credit scores for instant decisions on credit allowance, insurance premiums, surety bond costs, and more, FreightCredit offers an on-demand Credit Score checker to help your company make similar informed choices.
          <br /><br />
          FreightCredit.com is the only on-demand credit score checker specifically designed for the freight industry and works closely with FreightValidate to combat fraud in transportation activities.
          <br /><br />
          The FreightCredit Score, along with Days-to-Pay data, is now available for nearly every shipper and freight broker.
          <br /><br />
          As a user of our services, you gain the ability to make rapid, informed decisions about moving freight, ensuring you are paid promptly and securely for your services.
          <br /><br />
          Don’t leave your business to chance—use FreightCredit and FreightValidate to stay ahead in the industry.
        </p>
      </div>

      <div className='flex flex-col items-center w-full p-5 mb-5 gap-5 mt-10 text-md italic'>
        
        <div id="pricing" className="mt-8 w-full max-w-md bg-gray-100 p-6 rounded-lg shadow-md text-center border border-gray-300">
          <h2 className="text-xl font-bold mb-4 text-[#307a3f]">Pricing</h2>
          <ul className="text-gray-700 text-lg">
            <li className="mb-2">1 Lookup: <span className="font-semibold">$4.99</span></li>
            <li className="mb-2">10 Lookups: <span className="font-semibold">$15.99</span></li>
            <li className="mb-2">25 Lookups: <span className="font-semibold">$29.99</span></li>
            <li className="mb-2">50 Lookups: <span className="font-semibold">$59.99</span></li>
            <li className="mb-2">100 Lookups: <span className="font-semibold">$109.99</span></li>
            <li className="mb-2">1000 Lookups: <span className="font-semibold">$599.00</span></li>
          </ul>
          <button className="bg-[#307a3f] text-white px-4 py-2 rounded-lg mt-4" onClick={openPaymentPopup}>
            Buy Credits
          </button>
        </div>

        <p className="text-gray-700 text-center mt-8 w-full max-w-4xl mx-auto">
        The information provided by TransCredit & Collaborative Rating Systems
LLC is for their usage only and is not to be retransmitted or furnished to others” and Customer
hereby certifies and agrees that it will request and use such credit information solely in connection
with transactions involving the consumer or business entity as to whom credit information is sought
and will not request or use such information for purposes prohibited by public law. All such
information shall be maintained by the Customer in strict confidence and disclosed only to employees
whose duties reasonably relate to the legitimate business purposes for which the information is
released.
        </p>
        <img className="w-70 mt-8" src="/credit.png" alt="credit" />
        <button className="bg-[#307a3f] text-white px-4 py-2 rounded-lg mt-8" onClick={() => {
          window.open('https://www.transcredit.com/TransCredit.aspx?utm_source=freightvalidate&utm_medium=referral', '_blank');

        }}>
        Click Here for More Info About TransCredit
        </button>
      </div>

      {isSearchResultsPopupOpen && (
        <SearchResultsPopup
          searchResults={searchResults}
          handleCompanySelect={handleCompanySelect}
          closePopup={closeSearchResultsPopup}
        />
      )}

      {isPaymentPopupOpen && (
        <PaymentPopup
          closePaymentPopup={closePaymentPopup}
          purchaseLookups={purchaseLookups}
          loading={loading}
        />
      )}

      {isReportPopupOpen && (
        <ReportPopup
          reportData={reportData}
          closeReportPopup={closeReportPopup}
        />
      )}

      <div className="mt-auto py-5 w-full bg-gray-200 text-center">
        <a href="/PrivacyNotice.pdf" target="_blank" className="text-sm text-[#307a3f] mx-2">Privacy Policy</a>
        <a href="/CollaboRateTermsofServiceUpdated8-24.pdf" target="_blank" className="text-sm text-[#307a3f] mx-2">Terms and Conditions</a>
      </div>
    </div>
  );
};

export default ValidationPage;
