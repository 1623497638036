import React, { useState, useEffect, useRef } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ReportPopup = ({ reportData, closeReportPopup }) => {
  const [showScrollButton, setShowScrollButton] = useState(false);
  const resultRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (resultRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = resultRef.current;
        setShowScrollButton(scrollHeight > clientHeight && scrollTop < scrollHeight - clientHeight - 20);
      }
    };

    if (resultRef.current) {
      resultRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (resultRef.current) {
        resultRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const scrollToBottom = () => {
    if (resultRef.current) {
      resultRef.current.scrollTo({ top: resultRef.current.scrollHeight, behavior: 'smooth' });
    }
  };

  const creditScoreData = reportData.HistoryCSDTP.CreditScorePayTrendFR.map((entry) => ({
    month: new Date(entry.ScoreDate).toLocaleString('default', { month: 'short' }),
    creditScore: parseInt(entry.CreditScore, 10),
    avgDaysToPay: parseInt(entry.AverageDaysToPay, 10),
  })).slice(-12);

  return (
    <div ref={resultRef} className="fixed inset-0 flex items-start justify-center bg-black bg-opacity-75 z-50 animate-fadeIn overflow-y-auto">
      <div className="p-8 rounded-lg w-full max-w-3xl relative border border-gray-300 bg-white text-black">
        <button
          className="absolute top-4 right-4 text-black text-2xl"
          onClick={closeReportPopup}
        >
          &times;
        </button>
        <div className="flex flex-col items-center w-full">
          <h2 className="text-2xl font-semibold mb-2 text-center">{reportData.CompanyName}</h2>
          <p className="text-gray-600 mb-2">Credit Score: {reportData.CreditScore}</p>
          <p className="text-gray-600 mb-4">Average Days to Pay: {reportData.Days_To_Pay}</p>

          <div className="mb-6 w-full">
            <h3 className="text-xl font-semibold mb-2">Credit Score Over Time</h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={creditScoreData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="creditScore" fill="#307a3f" name="Credit Score" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="mb-6 w-full">
            <h3 className="text-xl font-semibold mb-2">Average Days to Pay Over Time</h3>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={creditScoreData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="avgDaysToPay" fill="#8884d8" name="Avg Days to Pay" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        {showScrollButton && (
          <button
            className="fixed bottom-10 bg-[#307a3f] text-white px-4 py-2 rounded-lg z-550 left-0 right-0 mx-auto w-40"
            onClick={scrollToBottom}
          >
            Scroll to Bottom
          </button>
        )}
      </div>
    </div>
  );
};

export default ReportPopup;
