import React from 'react';

const InfoPopup = ({ isOpen, onClose }) => {
  if (!isOpen) return null; // Do not render the popup if it's not open

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md mx-4 relative">
        <button
          className="absolute top-4 right-4 text-black text-2xl focus:outline-none"
          onClick={onClose}
        >
          &times;
        </button>
        <div className="text-center">
          <h2 className="text-xl font-bold mb-4">Purchase Credits</h2>
          <p className="text-gray-600 mb-6">
            Please use our website to purchase credits and enhance your experience.
          </p>
          <button
            className="bg-[#307a3f] text-white px-4 py-2 rounded-lg hover:bg-[#3a6cd4] transition duration-200 focus:outline-none"
            onClick={onClose}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default InfoPopup;
