import React from 'react';

const AboutUs = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-6">About Us</h1>
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow">
        <p className="text-gray-700 mb-4">
          Welcome to FreightValidate, the premier Carrier & Freight Broker Identity Verification and Vetting System serving Shippers, Carriers, Freight Brokers, Load-boards, and factoring companies. Our platform ensures regulatory compliance and swiftly identifies any past, present, or potential fraudulent or double brokering threats within the industry.
        </p>
        <h2 className="text-2xl font-semibold mb-3">Our Mission</h2>
        <p className="text-gray-700 mb-4">
          To attain "FreightValidated" status, Carriers or Brokers must meet stringent criteria: an active Motor Carrier Authority & DOT Number for over 30 days (without pending cancellations), a verified Principal Place of Business (excluding post office boxes), and a clean record without fraud or double broker activity. Our thorough verification process categorizes entities as “Validated,” “Not Validated,” “Warning,” or “Caution” based on compliance status and reports received.
        </p>
        <p className="text-gray-700 mb-4">
          While FreightValidate is a robust tool, it doesn't replace the need for comprehensive 3rd Party Carrier Verification, Monitoring, or Evaluation. We don't report on safety compliance, inspection, driver fitness, or mechanical results. We strongly recommend thorough vetting through FreightValidate and reputable companies like www.MyCarrierPackets.com or your Company’s Compliance Department protocols to ensure compliance and safety.
        </p>
        <p className="text-gray-700">
          FreightValidate is committed to bolstering the integrity and security of the transportation industry by providing a reliable verification platform, emphasizing the significance of comprehensive carrier compliance and safety vetting.
        </p>
        <h2 className="text-2xl font-semibold mt-6 mb-3">Our Founder</h2>
        <p className="text-gray-700 mb-4">
          Dale Prax is a devoted dad, husband, grandfather, friend, mentor, and revered Marine Corps veteran. His journey encompasses innovation, entrepreneurship, and a relentless pursuit of excellence.
        </p>
        <p className="text-gray-700">
          As the Founder of FreightValidate, Dale personifies visionary leadership, driving positive change within the industry. His commitment extends beyond business, as evidenced by his creation of two nonprofit organizations, a pioneering freight cost rating company, and a transformative social media platform for freight professionals.
        </p>
        <p className="text-gray-700 mb-4">
          FreightValidate symbolizes Dale's unwavering dedication to fostering a safer and more compliant transportation landscape. Creating a future where integrity and innovation thrive hand in hand.
        </p>
        {/* Assuming you will add images and links dynamically here */}
      </div>
    </div>
  );
};

export default AboutUs;
