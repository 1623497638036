import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ContactUs from './ContactUs';
import ValidationPage from './ValidationPage';
import AboutUs from './AboutUs';
import TermsOfUse from './TermsOfUse';
import TermsConditions from './TermsConditions';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/contact" element={<ContactUs />} />
        <Route path='/about' element={<AboutUs />} />
        <Route path="/terms" element={<TermsOfUse />} />
        <Route path="/conditions" element={<TermsConditions />} />
        <Route path="/*" element={<ValidationPage />} />
      </Routes>
    </Router>
  );
}

export default App;
